import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { useState } from 'react'
import { getUser } from '../utils';
import { useLocation, useNavigate } from 'react-router';
import { withRoot, Layout, Copyright, Typography } from '../modules'
import { Snackbar, Alert } from '@mui/material';


function ForgotPassword() {
  const location = useLocation() as any
  const navigate = useNavigate()
  const [popupMsg, setPopupMsg] = useState('')
  const [isLoading, setLoading] = useState(false)

  const validationSchema = yup.object({
    phone: yup
      .string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      phone: location?.state?.user || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const user = getUser(values)
      setLoading(true)
      try {
        await ServerApi.sendForgotPasswordCode(user)
        navigate('/reset-password', { state: { user }})
      } catch(err: any) {
        if (err.response?.status === 404){
          setPopupMsg("User not found")
        } else if(err.response?.data?.error) {
          setPopupMsg(err.response?.data?.error)
        } else {
          setPopupMsg(err.message || 'error')
        }
      }
      setLoading(false)
    },
  });

  return (
    <Layout
      sxBackground={{
        backgroundColor: 'secondary.main',
        backgroundPosition: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
          <Alert onClose={() => setPopupMsg('')} severity="error" sx={{ width: '100%' }}>
            {popupMsg}
          </Alert>
        </Snackbar>
        <Typography component="h1" variant="h4" sx={{fontWeight: 550}}>
          Forgot Your Password? 
        </Typography>
        <Typography component="h1" variant="body1">
            To reset your password, enter the registered phone number on your account. 
            We will send you a verification code to the phone number you provided shortly.
            Once you received the verification code, the next step below will take you to the update password page so you can reset your password.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete='phone'
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone} 
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              Send Code
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                Don't have an account?&nbsp;&nbsp; 
                <Link href="" variant="body2" onClick={() => navigate('/register')}>
                    Sign Up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Layout>
  );
}

export default withRoot(ForgotPassword)