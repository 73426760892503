import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography, withRoot } from '../modules'
import { ListItem } from '@mui/material';


function Terms() {
  return (
    <React.Fragment>
      <Container >
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h1" gutterBottom marked="center" align="center">
            Terms
          </Typography>
            <Typography variant="body2" display="inline">
              Information published on LGBToken
              The website https://LGBT.com (hereinafter, referred to as the "Website") provides information and material of a general nature. You are not authorized and nor should you rely on the Website for legal advice, business advice, or advice of any kind. You act at your own risk in reliance on the contents of the Website. Should you make a decision to act or not act you should contact a licensed professional in the relevant field in which you want or need help. In no way are the owners of, or contributors to, the Website responsible for the actions, decisions, or other behavior taken or not taken by you in reliance upon the Website.

              <Typography variant="h5" sx={{mt:3}}>Translations</Typography>
                The Website may contain translations of the English version of the content available on the Website. These translations are provided only as a convenience. In the event of any conflict between the English language version and the translated version, the English language version shall take precedence. If you notice any inconsistency, please report them on GitHub.

              <Typography variant="h5" sx={{mt:3}}>Risks related to the use of LGBT</Typography>
                The Website will not be responsible for any losses, damages or claims arising from any events included but not limited to the events listed below.

              <ListItem sx={{ padding: 3, listStyleType: "disc", display: "list-item",}}>
                Mistakes made by the user of any LGBT-related software or service, e.g., forgotten passwords, payments sent to wrong LGBT addresses, and accidental deletion of wallets.
              </ListItem>
              <ListItem sx={{ padding: 3, listStyleType: "disc", display: "list-item",}}>
              Software problems of the Website and/or any LGBT-related software or service, e.g., corrupted wallet file, incorrectly constructed transactions, unsafe cryptographic libraries, malware affecting the Website and/or any LGBT-related software or service.
              </ListItem>
              <ListItem sx={{ padding: 3, listStyleType: "disc", display: "list-item",}}>
              Technical failures in the hardware of the user of any LGBT-related software or service, e.g., data loss due to a faulty or damaged storage device.
              </ListItem>
              <ListItem sx={{ padding: 3, listStyleType: "disc", display: "list-item",}}>
              Security problems experienced by the user of any LGBT-related software or service, e.g.,unauthorized access to users' wallets and/or accounts.
              </ListItem>
              <ListItem sx={{ padding: 3, listStyleType: "disc", display: "list-item",}}>
              Actions or inactions of third parties and/or events experienced by third parties, e.g., bankruptcy of service providers, information security attacks on service providers, and fraud conducted by third parties.
              </ListItem>

              <Typography variant="h5" sx={{mt:3}}>Sponsored Content and Advertisements</Typography>
              This Website features sponsored content and advertisements that are served based on metrics such as device type and visitor location to better tailor products and services that may be of interest to visitors. This content is marked with (■ or AD) as an indication that the visitor is viewing sponsored content where applicable.

              <Typography variant="h5" sx={{mt:3}}>Investment risks</Typography>
              The investment in LGBT can lead to loss of money over short or even long periods. The investors in LGBT should expect prices to have large range fluctuations. The information published on the Website cannot guarantee that the investors in LGBT would not lose money.

              <Typography variant="h5" sx={{mt:3}}>Compliance with tax obligations</Typography>
              The users of the Website are solely responsible to determinate what, if any, taxes apply to their LGBT transactions. The owners of, or contributors to, the Website are NOT responsible for determining the taxes that apply to LGBT transactions.

              <Typography variant="h5" sx={{mt:3}}>The Website does not store, send, or receive LGBTs</Typography>
              The Website does not store, send or receive LGBTs. This is because LGBTs exist only by virtue of the ownership record maintained in the LGBT network. Any transfer of title in LGBTs occurs within a decentralized LGBT network, and not on the Website.

              <Typography variant="h5" sx={{mt:3}}>No warranties</Typography>
              The Website is provided on an "as is" basis without any warranties of any kind regarding the Website and/or any content, data, materials and/or services provided on the Website.

              <Typography variant="h5" sx={{mt:3}}>Limitation of liability</Typography>
              Unless otherwise required by law, in no event shall the owners of, or contributors to, the Website be liable for any damages of any kind, including, but not limited to, loss of use, loss of profits, or loss of data arising out of or in any way connected with the use of the Website.

              <Typography variant="h5" sx={{mt:3}}>Arbitration</Typography>
              The user of the Website agrees to arbitrate any dispute arising from or in connection with the Website or this disclaimer, except for disputes related to copyrights, logos, trademarks, trade names, trade secrets or patents.
            </Typography>      
        </Box>
      </Container> 
    </React.Fragment>
  );
}

export default withRoot(Terms);
