import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import logo from '../../static/LGBT-logo-rb.png'
import { SessionContext} from '../../App';
import { useContext } from "react";
import ListItemButton from '@mui/material/ListItemButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Tooltip } from '@mui/material';

interface MenuItems {
  name: string, 
  url: string,
  variant: "outlined"|"contained",
  color: "primary.main"|"secondary.main"
}

const ResponsiveAppBar = () => {
  const sessionState = useContext(SessionContext);
  
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event : React.ChangeEvent<any>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { user, token } = sessionState || {}
  const activeSession = user&&token

  const menuItems: MenuItems [] = [
    {
      name:"Donation Tracker",
      url:"donation-tracker",
      variant:"outlined",
      color:"primary.main", 
    },
  ]

  if(!activeSession){
    menuItems.push({
      name:"Login",
      url:"login",
      variant:"outlined",
      color:"primary.main", 
    })
    menuItems.push({
      name:"Register",
      url:"register",
      variant:"contained",
      color:"secondary.main", 
    })
  }

  return (
    <AppBar elevation={0} color="secondary" position="static" sx={{width:"100%", mb: 5}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <Box
            component="img"
            src={logo}
            sx={{ 
              width: '5vw',
              '@media (max-width:600px)': {
                width: '14vw',
              },
              '@media (min-width:1280px)': {
                width: '70px',
              },
              m: 2,
            }}>
          </Box>

          <Typography
            noWrap
            component="div"
            sx={{ mr: 2, color: 'primary.main', display: {  md: 'flex' } }}
          >
            <Link
              variant="h3"
              underline="none"
              href="/home"
              sx={{  
                color: 'primary.dark',
                '@media (max-width:600px)': {
                  fontSize: '1.2rem',
                },
             }}
            >
              Let's Go Brandon
            </Link>
          </Typography>

          <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none'  },
              }}
              color="primary"
            >
              {activeSession && 
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  variant="h6"
                  underline="none"
                  href='/dashboard'
                  sx={{  color: 'primary.dark' }}
                >
                    Wallet
                </Link>
              </MenuItem>
              }
              {menuItems.map((item) => (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    variant="h6"
                    underline="none"
                    href={item.url}
                    sx={{  color: 'primary.dark' }}
                  >
                      {item.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          
          <Stack spacing={2} direction="row" alignItems="center" >
            {menuItems.map((item) => (
              <Button variant={item.variant} color="primary" href={item.url}>
                <Typography
                  variant="h6"
                  sx={{color: item.color}}>
                  {item.name}
                </Typography>
              </Button>
             ))}
            {activeSession && 
              <Link
                variant="h6"
                underline="none"
                href='/dashboard'
                sx={{  color: 'primary.dark' }}
              >
                <Tooltip title="Wallet" placement="bottom">
                <ListItemButton>
                  <ListItemIcon>
                    <AccountBalanceWalletIcon sx={{ fontSize: 40 }} />
                  </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
           </Stack>  
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;