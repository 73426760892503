import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import YupPassword from 'yup-password'
import { ServerApi } from  '../api'
import { DefaultAppProps } from '../App';
import { useState } from 'react'
import { getUser, rePhoneNumber } from '../utils';
import { Snackbar, Alert, Container } from '@mui/material';
import { useNavigate } from 'react-router';
import { withRoot, Copyright, Typography } from '../modules'
import { useEffect } from "react";

YupPassword(yup)

function Register(props: DefaultAppProps) {
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [popupMsg, setPopupMsg] = useState('')
  const [referrerCode, setReferrerCode] = useState('')
  const yup = require('yup')

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    setReferrerCode(urlParams.get('referrerCode') || '');
  })

  const validationSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phone: yup.string()
      .matches(rePhoneNumber, "Enter a 10 digit US phone number")
      .required(),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Required'),
    city: yup
      .string(),
    state: yup
      .string()
      .min(2, 'State should be a minimum of 2 characters lenghth'),
    password: yup
      .string()
      .password()
      .required('Required'),
    referrerCode: yup
      .string()
      .matches(/^[A-Za-z0-9]/, 'Enter a 6 digit referrerCode code'),
    companyName: yup
      .string()
  });
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      city: '',
      state: '',
      password: '',
      referrerCode: referrerCode,
      companyName: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const userId = getUser(values)
      setLoading(true)
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: userId,
        email: values.email,
        address: {
          city: values.city,
          state: values.state
        },
        password: values.password,
        referrerCode: values.referrerCode,
        companyName: values.companyName,
      }

      try {
        await ServerApi.register(data)
        navigate('/login', { state: { user: userId, popupMsg: `Registration was a success! Login now and get verified.` } })
      } catch(err: any) {
        if (err.response?.data?.message[0]==='phone must be a valid phone number'){
          setPopupMsg('Enter a 10 digit US phone number')
        } else if(err.response?.status===409){
          setPopupMsg("This phone number has already been used.");
        } else if(err.response?.status===404){
          setPopupMsg("Invalid referral code.");
        } else if (err.response?.data?.error) {
          setPopupMsg(err.response?.data?.error)
        } else {
          setPopupMsg(err.message || 'error')
        }
      }
      setLoading(false)
    },
  });

  return (
    <Container>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
        <Alert onClose={() => setPopupMsg('')} severity="error" sx={{ width: '100%' }}>
          {popupMsg}
        </Alert>
        </Snackbar>
        <Typography component="h1" variant="h1">
          Register
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} >
                <TextField
                  autoComplete='given-name'  
                  name="firstName"
                  id="firstName"
                  label="First Name"
                  required
                  style ={{width: '100%'}}
                  inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}          
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                    autoComplete='family-name'
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    required
                    style ={{width: '100%'}}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName} 
                  />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete='tel'
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  required
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete='email'
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete='city'
                  style ={{width: '100%'}}
                  id="city"
                  label="City"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete='state'
                  style ={{width: '100%'}}
                  id="state"
                  label="State"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="companyName"
                  label="Company Name"
                  id="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="referrerCode"
                  label="Referral Code"
                  id="referrerCode"
                  value={formik.values.referrerCode}
                  onChange={formik.handleChange}
                  error={formik.touched.referrerCode && Boolean(formik.errors.referrerCode)}
                  helperText={formik.touched.referrerCode && formik.errors.referrerCode} 
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password} 
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                Already have an account?&nbsp;&nbsp; 
                <Link href="" variant="body2" onClick={() => navigate('/login')}>
                  Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

export default withRoot(Register)