import * as React from 'react';
import { ServerApi } from '../api';
import { useLayoutEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Typography } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import { ACTIONS } from './dashboardReducer';
import { AuthorizedUserInfo, UserInfoDispatcher } from '../types';


export default function SelectSuppotedMovements(props: {userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher, dispatch: any}) {
    const [allSupportedMovements, setAllSupportedMovements] = useState([]); 
    const [popupMsg, setPopupMsg] = useState('');

    const handleError = function( err: any ){
      if (err.response?.data?.error) {
        setPopupMsg(err.response?.data?.error)
      } else { 
        setPopupMsg(err.message || 'error')
      }
    }

    useLayoutEffect(() => {
        ServerApi.getSupportedMovements()
          .then ((res: any) => {
            setAllSupportedMovements(res);
          })
          .catch((err: any) => {
            handleError(err)
          })
    }, []);

    const addOrRemoveMovement = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const isChecked = event.target.checked;
      let isAlreadyAdded: boolean = props.userInfo.supportedMovements?.includes(name) ?? false
      if(isChecked && !isAlreadyAdded){
        if(props.userInfo.supportedMovements?.length === 3){
          setPopupMsg('You can only select 3')
        }else {
          props.setUserInfo({
            ...props.userInfo,
            supportedMovements: [...props.userInfo.supportedMovements ?? [],name],
          });
        }
      } 
      if(!isChecked && isAlreadyAdded) { // If user unchecked box then remove item from list
        props.setUserInfo({
          ...props.userInfo,
          supportedMovements: props.userInfo.supportedMovements?.filter(item => item !== name)
        });
      }
    }

    const updateSupportedMovements = () => {
      ServerApi.updateUser(props.userInfo.userId, {supportedMovements: props.userInfo.supportedMovements})
      props.dispatch({type: ACTIONS.SHOW_WALLET})
    }
    
    return(
      <Container>
        <CssBaseline />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
          <Alert onClose={() => setPopupMsg('')} severity="error" sx={{ width: '100%' }}>
          {popupMsg}
          </Alert>
        </Snackbar>
          <form onSubmit={updateSupportedMovements}>
            <Box 
              sx={{
                marginTop: 2, 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'left', 
              }}
            > 
                <Typography sx={{mt:10}} variant='h3'>To help us with the cause, please choose up to three issues you are mostly concerned about.</Typography>
                {Object.values(allSupportedMovements).map((movement) => ( 
                    <FormControlLabel 
                    control={<Checkbox checked={props.userInfo.supportedMovements?.includes(movement as string)} onChange={addOrRemoveMovement} />}  
                    label={movement as string}
                    name={movement as string}    
                    labelPlacement="end"
                    />
                ))}
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ width:'200px', height:'60px', mt: 3, borderRadius: 1  }}
                >
                    <Typography variant="h5" sx={{color:"secondary.main"}}>
                        Submit
                    </Typography>
                </Button>
            </Box>
          </form>
      </Container>
    )
  }