import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogOutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import { ACTIONS } from './dashboardReducer';
import { AuthorizedUserInfo, UserInfoDispatcher } from '../types';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';
import copyReferalLinkToClipboard from './utils/copyReferalLink';

export function MainListItems(props: {userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher, dispatch: any, setSession: any}){
  const navigate = useNavigate()
  const [popupMsg, setPopupMsg] = useState('')
  const [isError, setIsError] = useState(false)


  function setWalletVisible(){    
    props.dispatch({type: ACTIONS.SHOW_WALLET})
  }
  function setAccountVisible(){
    props.dispatch({type: ACTIONS.SHOW_ACCOUNT})
  }
  function navigateHome(){
    navigate('/home')
  }
  function logOut(){
    props.setSession("");
  }

  function copyReferralLink(){
    if(props.userInfo.referralCode){
      copyReferalLinkToClipboard(props.userInfo.referralCode)
      setPopupMsg("Copied referral link")
    } else {
      setIsError(true)
      setPopupMsg("Failed to copy link")
    }
  }

  return (
    <Box >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1000}       
        open={!!popupMsg}
        onClose={() => setPopupMsg('')}
      >
        <Alert onClose={() => setPopupMsg('')} severity={isError ? 'error' : 'success'}  sx={{ width: '100%' }}>
          {popupMsg}
        </Alert>
      </Snackbar>
      <Tooltip title="Wallet" placement="right">
        <ListItemButton onClick={setWalletVisible}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Wallet" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Profile" placement="right">
        <ListItemButton onClick={setAccountVisible}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Home" placement="right">
        <ListItemButton onClick={navigateHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Referral Link" placement="right">
        <ListItemButton onClick={copyReferralLink}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary="Referral Link" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Log Out" placement="right">
        <ListItemButton onClick={logOut}>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItemButton>
      </Tooltip>
    </Box>
  );
}