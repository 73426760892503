import axios, { Method } from 'axios'
import { AuthorizedUserInfo } from '../types'
import { getSessionCookie } from '../utils'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export interface DonationInfo {
  totalDonationAmount: number,
  pendingDonationAmount: number,
  donationEntities: {
    orgName: string,
    date: number,
    amount: number
  }[]
}

export interface UpdateUser {
  firstName?: string,
  lastName?: string,
  address?: {
    city?: string,
    state?: string
  },
  supportedMovements?: string[],
  companyName?: string
}

export interface ResetPassword {
  user: string,
  code: string, 
  newPassword: string
}


class ServerApiClz {

  async makeRequest(config: {
    method: Method,
    endpoint: string,
    params?: Record<string, any>,
    data?: Record<string, any>
  }) {
    const { method, endpoint, params, data } = config
    const url = new URL(endpoint, SERVER_URL)

    const resp = await axios.request({
      url: url.href,
      method,
      data,
      params,
    })
    return resp
  }

  async makeAuthorizedUserRequest(config: {
    method: Method,
    endpoint: string,
    params?: Record<string, any>,
    data?: Record<string, any>
  }) {
    const { method, endpoint, params, data } = config
    const url = new URL(endpoint, SERVER_URL)
    const { token, user } = getSessionCookie() || {}
    if (!(token && user)) {
      throw new Error('Invalid Session')
    }

    const resp = await axios.request({
      url: url.href,
      method,
      data,
      params,
      headers: {
        Authorization: token
      }
    })
    return resp
  }


  async register(data: {
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    password: string,
    companyName: string,
  }) {
    return this.makeRequest({
      method: 'POST',
      endpoint: 'user/register',
      data
    })
  }

  async login(data: { user: string, password: string }) {
    return this.makeRequest({
      method: 'POST',
      endpoint: 'user/login',
      data
    })
  }

  async refreshSession(user: string ) {
    return this.makeAuthorizedUserRequest({
      method: 'POST',
      endpoint: `user/${user}/refreshSession`
    })
  }
  
  async verify(data: { user: string, code: string }) {
    return this.makeRequest({
      method: 'POST',
      endpoint: 'user/verify',
      data
    })
  }

  async getDonationInfo() {
    const res = await this.makeRequest({
      method: 'GET',
      endpoint: 'donations/info'
    })
    return res.data
  }

  async getUserBalance(user: string){
    const res = await this.makeAuthorizedUserRequest({
      method: 'GET',
      endpoint: `user/${user}/balance`
    })
    return res.data
  }

  async transfer(params: {sender: string, recipient: string, amount: number}){
    const res = await this.makeAuthorizedUserRequest({
      method: 'POST',
      endpoint: `user/${params.sender}/transfer`,
      data: {
        fromAccountId: params.sender,
        toAccountId: params.recipient,
        value: params.amount
      }
    })
    return res.data
  }

  async getUserInfo(user: string): Promise<AuthorizedUserInfo> {
    const [userInfoResp, userBalanceResp] = await Promise.all([
      this.makeAuthorizedUserRequest({
        method: 'GET',
        endpoint: `user/${user}`
      }),
      this.makeAuthorizedUserRequest({
        method: 'GET',
        endpoint: `user/${user}/balance`
      })
    ]
    )
    return {...userInfoResp.data, ...userBalanceResp.data}
  }

  async updatePassword(userId: string, data: { oldPassword: string, newPassword: string }) {   
    return this.makeAuthorizedUserRequest({
      method: 'POST',
      endpoint: `user/${userId}/password`,
      data
    })
  }

  async sendForgotPasswordCode(userId: string) {   
    return this.makeRequest({
      method: 'POST',
      endpoint: `user/${userId}/sendResetPasswordCode`,
    })
  }

  async resendVerificationCode(userId: string) {   
    return this.makeRequest({
      method: 'POST',
      endpoint: `user/${userId}/resendVerificationCode`,
    })
  }

  async resetPassword(data: ResetPassword) {   
    return this.makeRequest({
      method: 'PATCH',
      endpoint: `user/resetPassword`,
      data
    })
  }

  async updateUser(id: string, data: UpdateUser){
    const res = await this.makeAuthorizedUserRequest({
      method: 'PATCH',
      endpoint: 'user/' + id,
      data
    })
    return res.data
  }

  async getSupportedMovements() {
    const res = await this.makeRequest({
      method: 'GET',
      endpoint: 'user/supportedMovements'
    })
    return res.data
  }
}

export const ServerApi = new ServerApiClz()
