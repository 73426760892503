import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Register, Login, Verify, ForgotPassword, ResetPassword } from './Auth'
import { Home, Privacy, Terms, FAQ, DonationTracker, ContactUs } from "./Home";
import { Dashboard } from './dashboard'
import { setSessionCookie, getSessionCookie, Session, sessionLength } from './utils'
import { ServerApi, DonationInfo } from "./api";
import { AuthorizedUserInfo } from "./types";
import DashboardSkeleton from "./modules/components/DashboardSkeleton";

export const SessionContext = React.createContext(getSessionCookie());

export interface DefaultAppProps {
  setSession: any,
  sessionState: any,
  donationInfo: DonationInfo
}

function ProtectedRoutes(props: DefaultAppProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, token } = props?.sessionState || {}

  const [userInfo, setUserInfo] = useState<AuthorizedUserInfo | undefined>(undefined)
  let sessionTimeoutId: any = undefined
  let intervalId: any = undefined

  function updateSessionTimeout() {
    setSessionCookie({user: props.sessionState.user, token: token, expiration: new Date(Date.now() + sessionLength)})  
    clearInterval(sessionTimeoutId);
    sessionTimeoutId=setTimeout(() => {endSession()}, sessionLength);
  }
  async function refreshSessionToken () {
    await ServerApi.refreshSession(user)
      .then(resp => {
        props.setSession({user: props.sessionState.user, token: resp?.data?.token, expiration: new Date(Date.now() + sessionLength)})
      })
  }
  function endSession() {
    props.setSession("");
    clearInterval(intervalId)
    navigate('/login')
  }

  document.addEventListener("click", updateSessionTimeout);
  document.addEventListener("scroll", updateSessionTimeout);

  useEffect(() => {
    if (!(user && token)) {
      navigate('/home', { state: { from: location }})
      return
    }    

    intervalId = setInterval(refreshSessionToken, sessionLength - 10000); 
    sessionTimeoutId=setTimeout(() => {endSession()}, sessionLength);

    ServerApi.getUserInfo(user)
      .then(data => {
        setUserInfo(data)
      })
      .catch(err => {
        console.log(err)
        navigate('/home', { state: { from: location }})
      })
  }, [location, user, token, navigate])

  return (

    <div>
      {userInfo ?
        <Routes>          
          <Route path="/dashboard" element={<Dashboard userInfo={userInfo} defaultProps={props}/>} />
          <Route path="*" element={<Navigate to={'./dashboard'} />} />
        </Routes>

        : <DashboardSkeleton/>
      }
    </div>
  )
}

const App = () => {
  const [sessionState, setSessionState] = useState(getSessionCookie());

  const [donationInfo, setDonationInfo] = useState({
    totalDonationAmount: 0.0,
    pendingDonationAmount: 0.0,
    donationEntities: []
  } as DonationInfo)

  useLayoutEffect(() => {
    ServerApi.getDonationInfo().then(res => {
      setDonationInfo(res)
    })
  }, []);

  function setSession(session: Session) {
    setSessionCookie(session)
    setSessionState(session)
  }

  const defaultProps: DefaultAppProps = {
    setSession,
    sessionState,
    donationInfo
  }
  return (
    <SessionContext.Provider value={sessionState}>
        <Routes>
          <Route path="/verify" element={<Verify {...defaultProps} />} />
          <Route path="/login" element={<Login {...defaultProps} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<Register {...defaultProps} />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/donation-tracker" element={<DonationTracker {...defaultProps}  />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/home" element={<Home {...defaultProps} />} />
          <Route path="*" element={<ProtectedRoutes {...defaultProps} />} />
        </Routes>
    </SessionContext.Provider>
  );
}

export default App