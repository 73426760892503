export interface DashboardState {
    showWallet: boolean, 
    showAccount: boolean,
    showUpdatePassword: boolean, 
    showSelectSupportedMovements: boolean
  }

interface DashboardAction {
    type: string,
}

export const ACTIONS = {
    SHOW_WALLET: 'show_wallet',
    SHOW_ACCOUNT: 'show_account',
    SHOW_UPDATE_PASSWORD: 'show_update_password',
    SHOW_SUPPORTED_MOVEMENTS: 'show_supported_movements'
  }

export function reducer(state: DashboardState, action: DashboardAction){
    switch (action.type){
      case ACTIONS.SHOW_WALLET:
        return {
          showWallet: true,
          showAccount: false,
          showUpdatePassword: false,
          showSelectSupportedMovements: false
        }
      case ACTIONS.SHOW_ACCOUNT:
        return {
          showWallet: false,
          showAccount: true,
          showUpdatePassword: false,
          showSelectSupportedMovements: false
        }
      case ACTIONS.SHOW_UPDATE_PASSWORD:
        return{
          showWallet: false,
          showAccount: false,
          showUpdatePassword: true,
          showSelectSupportedMovements: false
        }
      case ACTIONS.SHOW_SUPPORTED_MOVEMENTS:
        return {
          showWallet: false,
          showAccount: false,
          showUpdatePassword: false,
          showSelectSupportedMovements: true
        }
      default:
        return state
    }
  }