import { createTheme } from '@mui/material/styles';

export const secondary = {
    light: '#f8f2e7',
    main: '#e7d3ae',
    dark: '#8b7f68',
  }

export const primary = {
    light: '#677780',
    main: '#a12b1d',
    dark: '#263c49',
  }

const theme = createTheme({
  
  palette: {
      primary,    
      secondary,
    background: {
      default: secondary.main,
      paper: secondary.main
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    h1: {
      textTransform: 'uppercase',
      fontSize: 40,
      fontWeight: 1000,
      '@media (min-width:600px) and (max-width:900px)': {
        fontSize: '5vw',
      },
      background: `-webkit-linear-gradient(275deg, ${primary.main} 20%, ${primary.dark} 80%)`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h2: {
      textTransform: 'uppercase',
      fontSize: 34,
      fontWeight: 1000,
      color: primary.dark,
      '@media (max-width:600px)': {
        fontSize: '1.6rem',
      }
      },
    h3: {
      textTransform: 'uppercase',
      fontSize: 26,
      fontWeight: 1000,
      color: primary.dark,
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      }
    },
    h4: {
      fontSize: 27,
      color: primary.dark,
      '@media (max-width:600px)': {
        fontSize: '1.3rem',
      }
    },
    h5: {
      fontSize: 24,
      color: primary.dark
    },
    h6: {
      fontSize: 18,
      color: secondary.main,
      '@media (max-width:600px)': {
        fontSize: '5vw',
      }
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 800,
      color: 'primary.dark',
    },
    body1: {
      fontSize: 24,
      fontWeight: 500,
      color: primary.dark,
    },
    body2: {
      fontSize: 20,
    },
    button: {
      backgroundColor: secondary.main,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${secondary.main} inset`,
            '-webkit-text-fill-color': 'black'
          },
        }
      }
    }
  },
});

export default theme;
