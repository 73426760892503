import Cookies from "js-cookie";


const SessionKey = 'lgbt_session'
export interface Session {
  user: string,
  token: string,
  expiration: Date
}

const tenMinutes = 10 * 60 * 1000
export const sessionLength = tenMinutes

export function setSessionCookie(session: Session): void {
  Cookies.remove(SessionKey);
  Cookies.set(SessionKey, JSON.stringify(session), { expires: session.expiration });
};

export function getSessionCookie(): Session | undefined {
  const sessionCookie = Cookies.get(SessionKey);
  if (!sessionCookie) {
    return
  }
  return JSON.parse(sessionCookie);
};