import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link  from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Typography, withRoot } from '../modules'
import Footer from './views/Footer';
import EmailIcon from '@mui/icons-material/Email';

const iconStyle = {
    color: 'primary.dark',
    backgroundColor: 'secondary',
    mr: 1,
    '@media (max-width:900px)': {
        display: "none"
      },
  };

function ContactUs() {
  return (
    <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
            <Grid container alignItems="center" spacing={0} sx={{mt: 2}} >
                <Grid item sm={12} md={8} >    
                    <Typography  sx={{fontSize:"50px", mt: 5}}>
                        Contact us at <Link>lgbtokenllc@gmail.com</Link> if you want to be a LGBToken ambassador, receive a donation or make a general comment. 
                        <Typography sx={{fontSize:"20px", color:"primary.main"}}>*Please include your first and last name</Typography>
                    </Typography>
                </Grid>
                <Grid item sm={12}  md={4} >
                <Box component="a" href="email" sx={iconStyle} >
                    <EmailIcon sx={{width:"300px", height:"auto", color:"primary.main", m:10, mt: 20}} />
                </Box>
                </Grid>
            </Grid>
        </Box>
        <Footer />
    </Container>
  );
}

export default withRoot(ContactUs);
