import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const LayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.black,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('xl')]: {
    height: '80vh',
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
});

interface LayoutProps {
  sxBackground: SxProps<Theme>;
}

function Layout(
  props: React.HTMLAttributes<HTMLDivElement> & LayoutProps,
) {
  const { sxBackground, children } = props;

  return (
    <LayoutRoot>
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '100vw',
          overflowX: 'hidden'
        }}
      >
        {children}
        <Background sx={sxBackground} />
      </Container>
    </LayoutRoot>
  );
}

export default Layout