import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography, withRoot } from '../modules'
import Footer from './views/Footer';
import { MISSION_STATEMENT } from './views/Mission';

interface QuestionsAndAnswers {
  question: string, 
  answer: string
}


const questionsAndAnswers: QuestionsAndAnswers [] = [
  {
    question:"Is LGBT a decentralized cryptocurrency?",
    answer:"While we use DLT (decentralized ledger technology) to build and host LGBT, the cryptocurrency itself is not decentralized as our goal is to donate to conservative organizations and support conservative movements while allowing the cryptocurrency to circulate. We do not believe decentralization is required to achieve the above goals. "
  },
  {
    question:"Which blockchain technology/framework are you using for LGBT?",
    answer:"Hyperledger-Fabric"
  },
  {
    question:"Why not use a decentralized platform such as the Ethereum network?",
    answer:"Due to the high transaction fee(gas) of the current Ethereum network and its volatility, it conflicts with our goal which is to simply donate to conservative organizations as new coins are minted. We also do not need consensus or mechanism such as proof-of-work to reach consensus for our blockchain nodes."
  },
  {
    question:"Will you consider moving to a decentralized blockchain platform/network in the future?",
    answer:"We would love to host LGBT on a truly decentralized blockchain platform/network if it’s energy efficient, has low and stable transaction fees, and can scale well during peak traffic."
  },
  {
    question:"Do I pay a transaction fee when trading LGBT coins?",
    answer:"Yes, the transaction fee per trade is 2%"
  },
  {
    question:"How does LGBT donation work?",
    answer:"For every coin that is minted/created, 20% of the coin (0.2 coins) are deposited in the donation account to be donated to conservative organizations on a quarterly basis or as needed at the discretion of the LGBToken board."
  },
  {
    question:"How do we know if the donation is truly working as promised?",
    answer:"Every mint transaction is followed by a transfer function to the donation account and can be viewed on the LGBT Blockchain Explorer (xxxx.com). Every donation is recorded and tracked on our donation tracker page as well as recorded and viewable on the LGBT Blockchain Explorer."
  },
  {
    question:"What’s the mission statement for LGBToken?",
    answer: MISSION_STATEMENT
  },
  {
    question:"What happens if I forget my username?",
    answer:"Your username is the same as your telephone number."
  },
  {
    question:"What happens if I forgot my password?",
    answer:"You can reset your password using the forget password link on login page."
  },
  {
    question:"How do I update my username/phone number?",
    answer:"Your username and phone number is the unique identifier for accessing your wallet and account, therefore, for the best safety and protection of your account, we have decided to not allow anyone to change the phone number through the website. You will need to contact us per instructions outlined in the Contact Us page."
  },
  {
    question:"What’s the LGBToken minting process?",
    answer:"Anyone can mint by registering for an account on the LGBToken website. One LGBToken coin is minted when one account is registered, 0.5 coin is minted when a referral code is used to register an account."
  },
  {
    question:"What does LGB stand for in LGBToken?",
    answer:"Let’s Go Brandon"
  },
  {
    question:"What’s the value of the LGBToken?",
    answer:"Being a conservative is believing in free markets, as such, the value of the LGBToken will be directly related to supply and demand on the free-market. The initial valuation will be 1 LGBToken to $0.05 USD"
  },
  {
    question:"Will LGBToken mint coins as they wish like the Federal Government does?",
    answer:"No. The amount of Token minted is directly related to the amount of verified accounts signed up."
  },
  {
    question:"What’s the smart contract of the LGBToken?",
    answer:"Out of every coin minted, 0.75 coin will go to the person who minted the coin. 0.2 coins will go to the “donation account” which will be donated to Conservative campaigns, organizations and/or movements periodically. 0.05 coin will go to the LGBToken LLC as fee to support the operations"
  }
]


function FAQ() {
  return (
    <Container>
        <Box sx={{ mt: 5, mb: 5 }}>
          <Typography variant="h1" gutterBottom marked="center" align="center">
            FAQ
          </Typography>

          {questionsAndAnswers.map((item) => (
            <Container >
                <Typography variant="h3" sx={{mt: 5}}>
                    {item.question}
                </Typography>
                <Typography variant="body1" sx={{mt: 2}}>
                    {item.answer}
                </Typography>        
            </Container>
            ))}

        </Box>
        <Footer />
    </Container>
  );
}

export default withRoot(FAQ);
