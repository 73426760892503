import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { DefaultAppProps } from '../../App';
import { Button, Typography, Layout, FormattedNumber } from '../../modules';
import logo from '../../static/LGBT-logo-no-color.png'

export const MISSION_STATEMENT="LGBToken is a cryptocurrency that is designed for the Conservatives to champion the worldwide conservative movement to combat the ever-growing government overreach from the Leftists. 20% of every LGBToken minted shall be donated to the Conservative campaigns, organizations, and anti-woke movements around the Globe."

export default function Mission(props: DefaultAppProps) {

  const { user, token } = props.sessionState || {}
  const activeSession = user&&token

  return (
    <Layout
      sxBackground={{
        backgroundPosition: 'center',
      }}
    >
      <Grid container spacing={2}
        sx={{
          '@media (min-width:800px) and (max-width:1200px)': {
            width: '70%'
          },
        }}
      >
        <Grid item md={12} lg={6}>
          <Typography
            align="center"
            variant="h1"
            sx={{ mb: 3,
              '@media (min-width:1536px)': {
                mt: 1,
              },
            }}
          >
            Give to be Free
          </Typography>

          <Typography display="inline" variant="h5">
            {MISSION_STATEMENT}
          </Typography>
    
          <Typography
            align="center"
            variant="subtitle1"
            sx={{ mt: 2 }}
          >
            Get a coin! Give a coin! Donate a coin!
          </Typography>

          {!activeSession && 
            <Button
              variant="contained"
              href="/register"
              fullWidth
              sx={{ mt: 3, borderRadius: 1  }}
              >
              <Typography variant="h6">
                Register Now
              </Typography>
            </Button>
          }

          <Typography variant="subtitle1" sx={{ mt: 2, }}>
            To learn more about LGBToken, please visit our <Link href="/faq">FAQ</Link> page for more information.
          </Typography>
          <Typography variant="h5" align="center" sx={{ mt: 2, fontStyle: 'italic' }}>
            <FormattedNumber number={props.donationInfo.totalDonationAmount} /> coins donated
          </Typography>
        </Grid>
        <Grid item  md={0} lg={6}
        sx={{ 
          overflowX: 'hidden',
          maxWidth: '100px',}}>
        <Box
          component="img"
          src={logo}
          sx={{ 
            maxWidth: '600px',
            position: 'fixed',
            '@media (max-width:600px)': {
              display: "none"
            },
            '@media (min-width:600px) and (max-width:1200px)': {
              display: "none"
            },
            '@media (min-width:1280px)': {
              maxWidth: '800px',
              mt: 5,
            },
            mt: 20,
            opacity: 0.1,
            transform: "rotate(340deg)",
          }}>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
