import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DefaultAppProps } from '../App';
import { Typography, Layout, withRoot, FormattedNumber } from '../modules';


function DonationTracker(props: DefaultAppProps) {
  return (
    <Layout
    sxBackground={{
        backgroundPosition: 'center',
    }}
    >
    <Typography
        variant="h1"
        sx={{ mb: 3, mt: { sx: 4, sm: 5 }}}
    >
        Donation Tracker
    </Typography>

    <Grid container alignItems="center" spacing={0} sx={{mt: 2}} >
        <Grid item xs={6} sx={{mb: 1}} >
            <Box alignContent="flex-end" sx={{mt: 2, mb: 2}} >
                <Typography variant="h3">
                    Total:
                    <Typography variant="h3" sx={{m:1, textDecoration: 'underline black', color: 'primary.main', display:'inline-block'}}>
                        <FormattedNumber number={props.donationInfo.totalDonationAmount} /> LGBT
                    </Typography>
                </Typography>
            </Box>
        </Grid>

        <Grid item xs={6} sx={{ borderLeft: '1px solid '}}>
            <Box alignContent="flex-end" sx={{m:1}}>
                <Typography variant="h3" align="right" sx={{justifyContent: "flex-end"}}>
                    Pending: 
                    <Typography variant="h3" sx={{m:1, textDecoration: 'underline black', color: 'primary.main', display:'inline-block'}}>
                        <FormattedNumber number={props.donationInfo.pendingDonationAmount} /> LGBT
                    </Typography>
                </Typography>
            </Box>
        </Grid>
    </Grid>

    <Typography variant="h2" sx={{mt: 2}}>Past donations</Typography>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{width: '100%', height: 'auto', border: '1px dashed grey'}}>
            <Box sx={{m:1}}>
            {
                props.donationInfo.donationEntities.map(donation => (
                    <Typography >{donation.orgName} - {[new Date(donation.date).toDateString()]} - <FormattedNumber number={donation.amount} /> LGBT</Typography>
                ))
            }
            </Box>
        </Box>
    </Layout>
  );
}

export default withRoot(DonationTracker);