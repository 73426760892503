import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { DefaultAppProps } from '../App';
import { useState } from 'react'
import { getUser, sessionLength } from '../utils';
import { useLocation, useNavigate } from 'react-router';
import { Snackbar, Alert } from '@mui/material';
import { StatusCodes } from 'http-status-codes'
import { withRoot, Layout, Copyright, Typography } from '../modules'


function Login(props: DefaultAppProps) {
  const location = useLocation() as any
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [popupMsg, setPopupMsg] = useState(location?.state?.popupMsg || '')

  const validationSchema = yup.object({
    phone: yup
      .string().required('Required'),
    password: yup
      .string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      phone: location?.state?.user || '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { password } = values
      const user = getUser(values)
      setLoading(true)
      try {
        const resp = await ServerApi.login({ user, password })
        const sessionExpirationTime = new Date(Date.now() + sessionLength)
        if (resp.status === StatusCodes.NON_AUTHORITATIVE_INFORMATION) {
          navigate('/verify', { state: { user, sessionExpirationTime }})
        } else {
          const { token } = resp.data
          await props.setSession({ user, token, expiration: sessionExpirationTime })
          navigate('/dashboard', { state: { user }})
        }
      } catch(err: any) {
        if (err.response?.status === 401){
          formik.errors.password = "Username or password is incorrect"
        } else if (err.response?.data?.error) {
          formik.errors.password = err.response?.data?.error
        } else {
          formik.errors.password = err.message || 'error'
        }
      }
      setLoading(false)
    },
  });

  return (
    <Layout
      sxBackground={{
        backgroundColor: 'secondary.main',
        backgroundPosition: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
          <Alert onClose={() => setPopupMsg('')} severity="info" sx={{ width: '100%' }}>
          {popupMsg}
        </Alert>
        </Snackbar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete='phone'
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password} 
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                Don't have an account?&nbsp;&nbsp; 
                <Link href="" variant="body2" onClick={() => navigate('/register')}>
                    Sign Up
                </Link>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link href="" variant="body2" onClick={() => navigate('/forgot-password', { state: { user: formik.values.phone }})}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Layout>
  );
}

export default withRoot(Login)