import Typography, {TypographyTypeMap} from '@mui/material/Typography'
import { SystemProps } from '@mui/system';

export default function Copyright(props: TypographyTypeMap['props']) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © LGBTCoin '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
