import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from '../api'
import { useState } from 'react'
import { Snackbar, Alert, Container, Typography, Grid, Button } from '@mui/material';
import { Redirect } from '@reach/router';
import { ACTIONS } from './dashboardReducer';
import { AuthorizedUserInfo, UserInfoDispatcher } from '../types';
import copyReferalLinkToClipboard from './utils/copyReferalLink';


export default function UpdateProfile(props: {userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher, dispatch: any}) {
  const [isLoading, setLoading] = useState(false)
  const [popupMsg, setPopupMsg] = useState('')
  const [isError, setIsError] = useState(false)
  const userId = props.userInfo.userId

  const validationSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    city: yup.string(),
    state: yup.string().min(2, 'State should be a minimum of 2 characters lenghth')
});

const formik = useFormik({
  enableReinitialize: true,
  initialValues: {
    firstName: props.userInfo.firstName,
    lastName: props.userInfo.lastName,
    city: props.userInfo.address?.city,
    state:  props.userInfo.address?.state,
    companyName: props.userInfo?.companyName,
  },
  validationSchema: validationSchema,
  onSubmit: async (values) => {
    setLoading(true)
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      address: {
        city: values.city,
        state: values.state
      },
      companyName: values.companyName,
    }
    try {
      await ServerApi.updateUser(userId, body)
      props.setUserInfo({...props.userInfo, ...body})      
      setPopupMsg("Updated");
      setIsError(false)
    } catch(err: any) {
      if (err.response?.data?.error) {
        setPopupMsg(err.response?.data?.error)
      } else {
        setPopupMsg(err.message || 'error')
      }
      setIsError(true)
    }
    setLoading(false)
  },
});

function copyReferralLink(){
  if(props.userInfo.referralCode){
    copyReferalLinkToClipboard(props.userInfo.referralCode)
    setPopupMsg("Copied referral link")
  } else {
    setIsError(true)
    setPopupMsg("Failed to copy link")
  }
}

if (!userId) {
  return <Redirect to='/home' />
}

return (
  <Container>
    <CssBaseline />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!popupMsg}
        onClose={() => setPopupMsg('')}
      >
      <Alert onClose={() => setPopupMsg('')} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
        {popupMsg}
      </Alert>
      </Snackbar>
      <Typography variant="h2">
        Update Profile
      </Typography>
      <Box sx={{ mt: 3, 
          '@media (min-width:600px)': {
              width:'600px' 
            },
            width:'95%'
      }} justifyContent="center">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4">Phone: {props.userInfo.phone}</Typography>
          <Typography variant="h4" sx={{mt:4}}>Email: {props.userInfo.email}</Typography>
          <Typography variant="h4" sx={{mt:4}}>Referral Code: {props.userInfo.referralCode} <Button onClick={copyReferralLink}>Copy Link</Button></Typography>
          <TextField
              autoComplete='given-name'  
              name="firstName"
              id="firstName"
              label="First Name"
              required
              style ={{width: '100%'}}
              inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}   
              sx={{mt:4}}       
          />
          <TextField
              autoComplete='family-name'
              id="lastName"
              label="Last Name"
              name="lastName"
              required
              style ={{width: '100%'}}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName} 
              sx={{mt:4}}
          />
          <TextField
              autoComplete='city'
              style ={{width: '100%'}}
              id="city"
              label="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city} 
              sx={{mt:4}}
          />
          <TextField
              autoComplete='state'
              style ={{width: '100%'}}
              id="state"
              label="State"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state} 
              sx={{mt:4}}
          />
          <TextField
              autoComplete='companyName'
              style ={{width: '100%'}}
              id="companyName"
              label="Company Name"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName} 
              sx={{mt:4}}
          />
          <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, height:'60px',color:"secondary.main" }}
          >
            <Typography variant="h5"sx={{color: 'secondary.main'}}>
              Update
            </Typography>
          </LoadingButton>
        </form>

        <Grid container spacing={2} sx={{mb:10}}>
          <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={ () => {
                props.dispatch({type: ACTIONS.SHOW_SUPPORTED_MOVEMENTS})
              }}
              sx={{height:'45px'}}
              fullWidth
              >
                <Typography sx={{fontSize:'11px', color:'primary.main'}}>
                  Select Supported Movements
                </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={ () => {
                props.dispatch({type: ACTIONS.SHOW_UPDATE_PASSWORD})
              }}
              sx={{height:'45px'}}
              fullWidth
              >
                <Typography sx={{fontSize:'11px', color:'primary.main'}}>
                  Update Password
                </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>
);
}