import * as React from 'react';
import AppFooter from './views/Footer';
import Mission from './views/Mission';
import { withRoot } from '../modules';
import { DefaultAppProps } from '../App';

function Index(props: DefaultAppProps) {
  return (   
    <React.Fragment>
      <Mission {...props} />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
