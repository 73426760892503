import React from "react";
import Typography from '@mui/material/Typography';
import Title from './Title';
import { FormattedNumber } from "../modules/"
import  Transfer  from "./Transfer"
import { AuthorizedUserInfo, UserInfoDispatcher } from "../types";

export default function Wallet(props: {userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher}) {  
  return (
    <React.Fragment>
      <Typography variant="h2" sx={{color: 'primary.main'}}>Balance</Typography>
      <Typography variant="h2" sx={{fontSize: '100px'}}>
        <FormattedNumber number={props.userInfo.balance} />
      </Typography>
      <Transfer {...props} />
    </React.Fragment>
  );
}
