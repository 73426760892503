import { withRoot } from '../'
import { Container, Grid, Skeleton, Stack } from "@mui/material";

function DashboardSkeleton(){
    return(
    <Stack spacing={1}>
      <Skeleton width={1200} height={175} />
      <Skeleton width={1200} height={70} />
      <Skeleton width={1200} height={60} />
    </Stack>
    )
}
export default withRoot(DashboardSkeleton)