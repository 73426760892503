import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from '../modules/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from './listItems';
import Wallet from './Wallet';
import MuiDrawer from '@mui/material/Drawer';
import { useLayoutEffect, useReducer, useState } from 'react';
import { AuthorizedUserInfo } from '../types';
import { useLocation, useNavigate } from 'react-router';
import { reducer, DashboardState } from './dashboardReducer';
import UpdatePassword from './UpdatePassword';
import UdpateProfile from './UpdateProfile';
import SelectSuppotedMovements from './SelectSuppotedMovements';
import { DefaultAppProps } from '../App';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


function DashboardContent(props: { userInfo: AuthorizedUserInfo, defaultProps: DefaultAppProps }) {
  const location = useLocation() as any
  const passedState = location?.state
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const initialState = {
    showWallet: passedState?.showWallet ?? true,
    showAccount:  passedState?.showAccount ?? false,
    showUpdatePassword:  passedState?.showUpdatePassword ?? false,
    showSelectSupportedMovements:  passedState?.showSelectSupportedMovements ?? false
  } as DashboardState
  const [state, dispatch] = useReducer(reducer, initialState)

  useLayoutEffect(() => {
    if (!userInfo.userId) {
      navigate('/login')
    }
  }, []);

  const [userInfo, setUserInfo] = useState(props.userInfo)
  const setSession = props.defaultProps.setSession

  const commonChildProps = {
    userInfo, setUserInfo, dispatch, setSession
  }

  return (
    <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar position="absolute" sx={{backgroundColor:'secondary.main', color:'primary.dark'}} elevation={0} open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              User: {userInfo.userId}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {MainListItems({...commonChildProps})}            
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            mt: 2
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {state.showWallet && <Wallet {...commonChildProps} />}
            {state.showUpdatePassword && <UpdatePassword {...commonChildProps} />}
            {state.showAccount && <UdpateProfile {...commonChildProps}/>}
            {state.showSelectSupportedMovements && <SelectSuppotedMovements {...commonChildProps}/>}
          </Container>
        </Box>
      </Box>
  );
}

export default function Dashboard(props: { userInfo: AuthorizedUserInfo, defaultProps: DefaultAppProps }) {
  return (
    <ThemeProvider theme={theme}>      
      <DashboardContent {...props} />;
    </ThemeProvider>
  );
}