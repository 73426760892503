import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import AppAppBar from './views/AppAppBar'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


export default function withRoot<P>(Component: React.ComponentType<P>) {
  function WithRoot(props: P) {
    return (
      <ThemeProvider theme={theme}>
        <Box>
            <Container  
              sx={{maxWidth:'1280px'}}
              >
                <CssBaseline />
                <AppAppBar/>
                <Component {...props} />
            </Container>
          </Box>
      </ThemeProvider>
    );
  }

  return WithRoot;
}