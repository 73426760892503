import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { useState } from 'react'
import { Snackbar, Alert, Container, Button} from '@mui/material';
import { ACTIONS } from './dashboardReducer';
import { AuthorizedUserInfo, UserInfoDispatcher } from '../types';


export default function UpdatePassword(props: {userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher, dispatch: any}) {
  const userId = props.userInfo.userId

  const [isLoading, setLoading] = useState(false)
  const [popupErrorMsg, setPopupErrorMsg] = useState('')
  const [popupSuccessMsg, setPopupSuccessMsg] = useState('')

  const validationSchema = yup.object({
    oldPassword: yup
      .string()
      .required('Required'),
    newPassword: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Required'),
    newPasswordConfirmation: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });

  const formik = useFormik({
    initialValues: {
      oldPassword:'',
      newPassword:'',
      newPasswordConfirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await ServerApi.updatePassword(userId, {...values})
        setPopupSuccessMsg('Updated')
      } catch(err: any) {
        if(err.response?.status===401){
          setPopupErrorMsg("Your current password does not match")
        } else if (err.response?.data?.error) {
          setPopupErrorMsg(err.response?.data?.error)
        } else {
          setPopupErrorMsg(err.message || 'error')
        }        
      }
      setLoading(false)
    },
  });

  return (
    <Container>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupErrorMsg}
          onClose={() => setPopupErrorMsg('')}
        >
          <Alert onClose={() => setPopupErrorMsg('')} severity="error" sx={{ width: '100%' }}>
            {popupErrorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupSuccessMsg}
          onClose={() => setPopupSuccessMsg('')}
        >
          <Alert onClose={() => setPopupSuccessMsg('')} severity="success" sx={{ width: '100%' }}>
            {popupSuccessMsg}
          </Alert>
        </Snackbar>
        <Button
          variant="outlined" 
          color="primary" 
          onClick={ () => {
            props.dispatch({type: ACTIONS.SHOW_WALLET})
          }}
          sx={{height:'45px', width:'300px'}}
        >
          back to wallet
        </Button>
        <Box sx={{ mt: 3, 
            '@media (min-width:600px)': {
                width:'600px' 
              },
              width:'95%'
        }} justifyContent="center">
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    name="oldPassword"
                    id="oldPassword"
                    label="Current password"
                    type="password"
                    required
                    style ={{width: '100%'}}
                    inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}   
                    sx={{mt:4}}       
                />
                <TextField
                    name="newPassword"
                    id="newPassword"
                    label="New password"
                    type="password"
                    required
                    style ={{width: '100%'}}
                    inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}   
                    sx={{mt:4}}       
                />
                <TextField
                    name="newPasswordConfirmation"
                    id="newPasswordConfirmation"
                    label="New password"
                    type="password"
                    required
                    style ={{width: '100%'}}
                    inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                    value={formik.values.newPasswordConfirmation}
                    onChange={formik.handleChange}
                    error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                    helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}   
                    sx={{mt:4}}       
                />

                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={isLoading}
                    disabled={isLoading}
                    sx={{ mt: 3, mb: 2, height:'60px' }}
                >
                    Update
                </LoadingButton>
            </form>
        </Box>
      </Box>
    </Container>
  );
}