import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'primary.dark',
  backgroundColor: 'secondary',
  mr: 1,
  '&:hover': {
    bgcolor: 'secondary.dark',
  },
};

export default function AppFooter() {
  return (
    <Container >

      <Stack spacing={1} direction="row" justifyContent="center">
        <Box component="a" href="twitter" sx={iconStyle}>
          <TwitterIcon />
        </Box>
        <Box component="a" href="https://facebook.com/lgbt/" sx={iconStyle}>
          <FacebookIcon />
        </Box>
        <Box component="a" href="instagram" sx={iconStyle}>
          <InstagramIcon />
        </Box>
        <Box component="a" href="email" sx={iconStyle}>
          <EmailIcon />
        </Box>
      </Stack>
      
      <Stack spacing={2} direction="row" justifyContent="center">

        <Link href="/terms"  sx={{ color: 'primary.light' }}>Terms</Link>
        <Link href="/privacy" sx={{ color: 'primary.light' }}>Privacy</Link>
        <Link href="/contact-us" sx={{ color: 'primary.light' }}>Contact Us</Link>

        <Box  sx={{ color: 'primary.light' }}>
          {'Copyright © LGBTCoin '}
          {new Date().getFullYear()}
          {'.'}
        </Box>
        
      </Stack>
    </Container>
  );
}
