import Typography from '@mui/material/Typography';
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { useFormik } from 'formik'
import TextField from "../modules/components/TextField";
import Container from "@mui/material/Container";
import { Grid  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { secondary,primary } from '../modules/theme';
import { useState } from 'react';
import { AuthorizedUserInfo, UserInfoDispatcher } from '../types';
import { getUser, rePhoneNumber } from '../utils';
import { PRECISION } from '../modules/components/FormattedNumber';


export default function Transfer({ userInfo, setUserInfo }: { userInfo: AuthorizedUserInfo, setUserInfo: UserInfoDispatcher}) {
  const [isTransferring, setIsTransferring] = useState(false)
  const validationSchema = yup.object({
    amount: yup
        .number()
        .min(1/Math.pow(10, PRECISION))
        .max(userInfo.balance)
        .required("Please enter a valid number"),
    recipient: yup
        .string()
        .matches(rePhoneNumber, 'Enter a 10 digit US phone number')
        .required('Required'),
  })
  const formik = useFormik({
      initialValues: {
          amount: 0,
          recipient: ''
        },
      validationSchema: validationSchema,
      onSubmit: async (body) => {
        setIsTransferring(true)
        body.recipient = getUser({phone:body.recipient})        
        try{
           await ServerApi.transfer({ sender: userInfo.userId, ...body})
           setUserInfo({
             ...userInfo,
             balance: userInfo.balance - body.amount
           })
        }  catch(err: any) {
            if(err.response?.status===404){
                formik.errors.recipient = `User ${body.recipient} doesn't exist`
            } else if (err.response?.status===500) {
                formik.errors.recipient = "Recipient cannot be the same as the sender"
            } else if (err.response?.data?.error) {
                formik.errors = err.response?.data?.error
            } else {
                formik.errors = err.message || 'error'
            }
        }
        setIsTransferring(false)
      }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
        <Container sx={{mt: 10}} >
            <Grid container spacing={2} >
                <Grid item xs={12} md={5} >
                    <TextField 
                        name="amount"
                        id="amount"
                        label="Send"
                        type="number"
                        fullWidth                
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}  
                        sx={{
                            input: {
                                color: primary.dark,
                                background: secondary.main
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography variant='body1' align='center' sx={{mt: 4}}>
                        to
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <TextField 
                        autoComplete='tel'
                        name="recipient"
                        id="recipient"
                        label="Recipient"
                        value={formik.values.recipient}
                        onChange={formik.handleChange}
                        error={formik.touched.recipient && Boolean(formik.errors.recipient)}
                        helperText={formik.touched.recipient && formik.errors.recipient}  
                        fullWidth
                        sx={{
                            input: {
                                color: primary.dark,
                                background: secondary.main
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={isTransferring}
                    disabled={isTransferring}
                    sx={{ mt: 3, mb: 2 }}
                    >
                    Send
                </LoadingButton>
                </Grid>
            </Grid>
        </Container>
    </form>
  );
}


