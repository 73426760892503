import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { DefaultAppProps } from '../App';
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { Snackbar, Alert, Button } from '@mui/material';
import { withRoot, Layout, Copyright, Typography } from '../modules'
import { StatusCodes } from 'http-status-codes';


function Verify(props: DefaultAppProps) {
  const [isLoading, setLoading] = useState(false)
  const [popupMsg, setPopupMsg] = useState('')
  const resoundCodeTimeoutLength = 60;
  const [timer, setTimer] = useState(resoundCodeTimeoutLength);
  const [disableButton, setDisableButton] = useState(false);

  const location = useLocation() as any
  const userId = location?.state?.user
  const navigate = useNavigate()

  useEffect(() => {
    if (!userId) {
      navigate('/login')
    }

    if (timer === 0) {
      setDisableButton(false);
      setTimer(resoundCodeTimeoutLength);
    } else if (disableButton) {
      const timerId = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }

  }, [navigate, userId, timer, disableButton])

  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(/[0-9]{6}/, 'Enter a 6 digit code')
      .required('Required'),
  });
  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const resp = await ServerApi.verify({ user: userId, code: values.code })
        if (resp.status !== StatusCodes.ACCEPTED) {
          throw new Error('Code did not match')
        }
        const { token } = resp.data
        props.setSession({ user: userId, token, expiration: location.state.sessionExpirationTime })
        navigate('/dashboard', { state: { 
          showWallet: false, 
          showAccount: false, 
          showUpdatePassword: false, 
          showSelectSupportedMovements: true,
          sessionExpirationTime: location.state.sessionExpirationTime
        }})
      } catch(err: any) {
          setPopupMsg("Failed to verify")
      }
      setLoading(false)
    },
  });

  const resendVerificationCode = () => {
    setDisableButton(true);
    ServerApi.resendVerificationCode(userId)
  };

  return (
    <Layout
      sxBackground={{
        backgroundColor: 'secondary.main',
        backgroundPosition: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
          <Alert onClose={() => setPopupMsg('')} severity="error" sx={{ width: '100%' }}>
            {popupMsg}
          </Alert>
        </Snackbar>
        <Typography component="h1" variant="h5">
          Verify phone number
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="code"
                  label="Verification Code"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code} 
                />
              </Grid>
              
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                  sx={{ mt: 3 }}
                >
                  Verify
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  disabled={disableButton}
                  onClick={resendVerificationCode}
                >
                  {disableButton ? `Resend in ${timer}s` : 'Resend Code'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Layout>
  );
}

export default withRoot(Verify)