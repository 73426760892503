import { LoadingButton } from '@mui/lab';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ServerApi } from  '../api'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { Snackbar, Alert, Link, Button } from '@mui/material';
import { withRoot, Layout, Copyright, Typography } from '../modules'
import { StatusCodes } from 'http-status-codes';


function ResetPassword() {
  const [isLoading, setLoading] = useState(false)
  const [popupMsg, setPopupMsg] = useState('')

  const location = useLocation() as any
  const userId = location?.state?.user
  const navigate = useNavigate()

  const resoundCodeTimeoutLength = 60;
  const [timer, setTimer] = useState(resoundCodeTimeoutLength);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (timer === 0) {
      setDisableButton(false);
      setTimer(resoundCodeTimeoutLength);
    } else if (disableButton) {
      const timerId = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [timer, disableButton])

  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(/[0-9]{6}/, 'Enter a 6 digit code')
      .required('Required'),
    newPassword: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Required'),
    newPasswordConfirmation: yup
      .string()
      .required('Required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
  });
  const formik = useFormik({
    initialValues: {
      code: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const resp = await ServerApi.resetPassword({ user: userId, code: values.code, newPassword: values.newPassword })
        if (resp.status !== StatusCodes.ACCEPTED) {
          throw new Error('Code did not match')
        }
        navigate('/login', { state: { user: userId, popupMsg: `Password has been reset.` } })
      } catch(err: any) {
        if (err.response?.status === 401){
          setPopupMsg("Invalid Verification Code")
        } else if (err.response?.data?.error) {
          setPopupMsg(err.response?.data?.error)
        } else {
          setPopupMsg(err.message || 'error')
        }
      }
      setLoading(false)
    },
  });

  const resendVerificationCode = () => {
    setDisableButton(true);
    ServerApi.sendForgotPasswordCode(userId)
  };

  return (
    <Layout
      sxBackground={{
        backgroundColor: 'secondary.main',
        backgroundPosition: 'center',
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!popupMsg}
          onClose={() => setPopupMsg('')}
        >
          <Alert onClose={() => setPopupMsg('')} severity="error" sx={{ width: '100%' }}>
            {popupMsg}
          </Alert>
        </Snackbar>
        <Typography component="h1" variant="h5">
          Enter verification code and new password.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 3 }}>

                <TextField
                  fullWidth
                  id="code"
                  label="Verification Code *"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code} 
                />
                <TextField
                    name="newPassword"
                    id="newPassword"
                    label="New password"
                    type="password"
                    required
                    style ={{width: '100%'}}
                    inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}   
                    sx={{mt:4}}       
                 />
                <TextField
                    name="newPasswordConfirmation"
                    id="newPasswordConfirmation"
                    label="New password"
                    type="password"
                    required
                    style ={{width: '100%'}}
                    inputProps={{ style: {WebkitBoxShadow: "0 0 0 1000px "+"${secondary.main}"+"inset" } }}
                    value={formik.values.newPasswordConfirmation}
                    onChange={formik.handleChange}
                    error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                    helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}   
                    sx={{mt:4}}       
                />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              Reset
            </LoadingButton>
            <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  disabled={disableButton}
                  onClick={resendVerificationCode}
                >
                  {disableButton ? `Resend in ${timer}s` : 'Resend Code'}
                </Button>
              </Grid>
          </Box>
        </form>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Layout>
  );
}

export default withRoot(ResetPassword)